import styled from 'styled-components';

export const Body = styled.div`
  background: #fff;
  color: #661988;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;
export const InnerBody = styled.div`
  width: 740px;
  margin: 0 auto;
  padding: 8.5vw 0;
  text-align: left;
  @media (max-width: 767px) {
    width: 90%;
    height: auto;
  }
`;
export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: #e5e5e5;
  margin: 50px 0;
  clear: both;
  opacity: 1;
  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;

export const Header3 = styled.h2`
  color: #00493b;
  font-family: 'Figtree-Medium', arial, sans-serif;
  font-size: 26px;
  line-height: 35px;
  margin-bottom: 35px;
  letter-spacing: 0.02em;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
`;
export const HeaderH4 = styled.h2`
  margin: 0 0 35px 0;
  padding: 40px 0 0 0;
  font-family: 'Figtree-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #661988;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.02em;
`;
export const ParaText = styled.p`
  margin: 0 0 30px 0;
  font-size: 18px;
  text-align: left;
  padding: 0;
  color: #666;
  font-family: 'Figtree-Regular', arial, sans-serif;
  line-height: 24px;
  letter-spacing: 0.02em;
  @media (max-width: 767px) {
    line-height: 22px;
  }
  b {
    font-family: 'Figtree-Regular', arial, sans-serif;
  }
  code {
    margin: 0 0 20px 0;
    padding: 0;
    text-align: center;
    color: #661988;
    font-family: 'Figtree-Regular', arial, sans-serif;
    font-size: 14px;
    letter-spacing: 0.02em;
  }
`;

export const Header2 = styled.h2`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Figtree-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #733695;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const Header = styled.h1`
  font-size: 32px;
  line-height: 42px;
  padding: 25px 0 0 0;
  margin: 0 0 25px 0;
  font-family: 'Figtree-Medium', arial, sans-serif;
  font-weight: normal !important;
  color: #00493b;
  @media (max-width: 991px) {
    font-size: 30px;
    line-height: 38px;
    padding-top: 20px;
  }
`;

export const SuperScriptStyle = styled.sup`
  vertical-align: top;
  font-size: 18px;
  line-height: 30px;
`;

export const LinkStyle = styled.a`
  text-decoration: none;

  transition: 0.25s ease-out;
  text-align: center;
  margin: 0 0 35px 0;
  font-family: 'Figtree-Regular', arial, sans-serif;
  cursor: pointer;
  color: #058268;
`;

export const FormBody = styled.div`
  margin: 0 auto;
  width: 543px;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
  }
  #mktoStyleLoaded {
  /* css load detection, do not remove */
  color:#123456;
}

.mktoForm {
	font-family: 'Figtree-Medium', arial, sans-serif !important;
	color: #666 !important;
}

.mktoForm fieldset {
  border:1px solid #aeb0b6;
  -webkit-border-radius:0;
  -moz-border-radius:0;
  border-radius:0;
}
.mktoForm fieldset legend{
  padding:0 1em;
}

.mktoForm .mktoLabel {
  padding-top: 0.7em;
}

.mktoForm input[type=text],
.mktoForm input[type=url],
.mktoForm input[type=email],
.mktoForm input[type=tel],
.mktoForm input[type=number],
.mktoForm input[type=date],
.mktoForm select.mktoField,
.mktoForm textarea.mktoField{
  -webkit-appearance: none;
  background-color:#e9dfee;
  line-height:1.5em;
  color:#000;
  border:none;
  -webkit-border-radius:0;
  -moz-border-radius:0;
  border-radius:0;
  padding:0.2em 0.3em;
  box-shadow:none;
  min-height:1.9em;
  padding: 10px;
  margin-top: 8px;
}
.mktoForm input[type=text]:focus,
.mktoForm input[type=url]:focus,
.mktoForm input[type=email]:focus,
.mktoForm input[type=tel]:focus,
.mktoForm input[type=number]:focus,
.mktoForm input[type=date]:focus,
.mktoForm select.mktoField:focus,
.mktoForm textarea.mktoField:focus{
  outline:none;
  border:none;
  color:#000;
}

.mktoForm select.mktoField::-ms-expand {
  display: none;
}

.mktoForm select.mktoField {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  padding:10px;
}


.mktoForm div.mktoLogicalField {
  border:1px solid transparent;
  -webkit-border-radius:7px;
  -moz-border-radius:7px;
  border-radius:7px;
}

.mktoForm input.mktoField.mktoInvalid,
.mktoForm select.mktoField.mktoInvalid,
.mktoForm textarea.mktoField.mktoInvalid,
.mktoForm div.mktoLogicalField.mktoInvalid{
  background: #ffd9d9;
  border: none;
}

.mktoForm .mktoRadioList > label,
.mktoForm .mktoCheckboxList > label{
  margin-bottom:0.6em;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mktoForm input[type=checkbox],
.mktoForm input[type=radio] {
  width:1em;
  height:1em;
  opacity:0;
}

.mktoForm input[type=checkbox] + label:before,
.mktoForm input[type=radio] + label:before
{
  content: "";  
  position:absolute;
  width:1em;
  height:1em;  
  margin-left:-1.5em;
  line-height:1em;
  background-color:#fff;
  box-shadow:inset 1px 1px 4px 1px #ddd;
  font-family:Arial, sans-serif;
  font-size:13px;
  border:1px solid #aeb0b6;
}

.mktoForm .mktoLabelToLeft input[type=checkbox] + label:before,
.mktoForm .mktoLabelToLeft input[type=radio] + label:before{
  margin-left:0;
  right:0.3em;
}

.mktoForm input[type=checkbox]:checked + label:before,
.mktoForm input[type=radio]:checked + label:before {
  text-align:center;
  color:#000;
}

.mktoForm input[type=checkbox]:focus + label:before,
.mktoForm input[type=radio]:focus + label:before{
  border:1px solid #888;
}

.mktoForm input[type=checkbox] + label:before{
  border-radius:2px;

}
.mktoForm input[type=checkbox]:checked + label:before{
  content:"\2713 ";
}

.mktoForm input[type=radio] + label:before{
  border-radius:1em;
}


.mktoForm.mktoNoCheckedSupport input[type=checkbox] + label:before,
.mktoForm.mktoNoCheckedSupport input[type=radio] + label:before{
  display:none;
}
.mktoForm.mktoNoCheckedSupport select.mktoField,
.mktoForm.ie7 select.mktoField,
.mktoForm.ie6 select.mktoField {
  background-image:none;
  padding-right:0.3em;
}

.mktoForm textarea.mktoField{
  resize: none;
}

button.mktoButton {
	padding: 14px 35px 12px 35px !important;
	margin: 0 5px 5px 0 !important;
	font-family: 'Figtree-Medium', arial, sans-serif !important;
	letter-spacing: .3px;
	font-size: 13px !important;
	line-height: 16px !important;
	text-transform: uppercase !important;
	text-align: left !important;
	color: #fff !important;
	background: #440e62 !important;
	border: none !important;
	box-shadow: none !important;
	}

@media screen and (max-width: 510px) {
.mktoButtonWrap {
	margin-left: 0 !important;
	}
}

`;
